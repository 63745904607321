export default {
    data: () => {
        return {
          fields: [
            { key: "full_name", label: "اسم المستفيد", sortable: true },
            { key: "phone_number", label: "رقم الموبايل" },
            { key: "dob", label: "تاريخ الميلاد" },
            { key: "gender", label: "الجنس" },
            { key: "actions", label: "" },
          ],
          estatesFormSchema: {
            first_name: {
              component: "FormInput",
              key: "first_name",
              attrs: {
                id: "first_name",
                label: "الاسم الأول",
                rules: "required",
              },
            },
            last_name: {
              component: "FormInput",
              key: "last_name",
              attrs: {
                id: "last_name",
                label: "الاسم الثاني",
                rules: "required",
              },
            },
            phone_number: {
              component: "FormMask",
              key: "phone_number",
              attrs: {
                options: "phone",
                rules: "required",
                label: "رقم الموبايل",
                dir: "ltr",
              },
            },
            dob: {
              component: "FormDatepicker",
              key: "dob",
              attrs: {
                id:"dob_beneficiary",
                label: "تاريخ الميلاد",
                rules: "required",
                placeholder: "YY-MM-DD"
              },
            },
            gender: {
              component: "FormSelect",
              key: "gender",
              attrs: {
                id: "gender",
                label: "الجنس",
                rules: "required",
                reduce: (el) => el.value,
                options: [
                  { label: "ذكر", value: "M" },
                  { label: "انثى", value: "F" },
                ],
              },
            },
          },
        };
      },
}