<template>
  <b-card>
    <header-table title="إدارة المستفيدين" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input label="" v-model="filter" placeholder="  ابحث هنا عن اسم المستفيد..." class="w-50" />
        </b-col>
      </b-row>
    </div>

    <data-table ref="estatesTable" :fields="fields" :filter="filter" ep="/beneficiaries">
      <template #cell(full_name)="row">
        {{ `${row.item.first_name} ${row.item.last_name}`}}
      </template>
      <template #cell(gender)="row">
        {{ row.item.gender == "M" ? "ذكر" : "انثى" }}
      </template>

      <template #cell(actions)="row">
        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
      </template>
    </data-table>
    <form-modal ref="estateModalBeneficiary" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
// import { DataTable, FormModal, HeaderTable, ActionsTable, FormInput, FormFile } from "@/components/components";
import DataTable  from "@/components/data-table/index.vue";
import FormModal from "@/components/FormModal.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import FormInput from "@/components/form-input/index.vue"
import FormFile from "@/components/form-file/index.vue"

import dataSchema from "./mixins"
export default {
  components: { HeaderTable, ActionsTable, BCard, DataTable, FormInput, BRow, BCol, FormModal, FormFile },
  data: () => {
    return {
      filter: "",
      activModel: false,
    };
  },
  mixins:[dataSchema],
  methods: {
    ...mapActions("settings/manageBeneficiary", [
      "update",
      "create",
      "delete",
    ]),

    onAdd() {
      this.$refs.estateModalBeneficiary.init({});
      this.activModel = true;
    },
    onUpdateClicked(form) {
      this.$refs.estateModalBeneficiary.init(form);
      this.activModel = true;
    },

    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف المستفيد", {
          title: "تأكيد حذف المستفيد",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.update({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },

  computed: {
    ...mapGetters("settings/manageBeneficiary", ["loading"]),
  },
};
</script>